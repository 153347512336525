import React from 'react';
import Form from './ContactForm';
import Helmet from 'react-helmet';
import ClientLogos from '../components/ClientLogos';
import ExperienceChooser from '../components/ExperienceChooser';

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
}

var review_token = 'dZPUCxEYX3VA3KQQDRKFJxrGS5YlM5joSy7w7qfrKA43Dv7h4H|BKdZ6EZaS8fhOR7A60WD4yfaEMtJMuDRkVgPJ3Qlo7oZB6x3Ii';
var review_target = 'review-container';


export default class TeamBuildingPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mobile: ($(window).width() <= 763)? true : false,
        }
        this.playVideoMobile = this.playVideoMobile.bind(this);
        this.playVideoDesktop = this.playVideoDesktop.bind(this);
        this.videoplayer = React.createRef();
    }

    playVideoMobile(){
      var videowrapper = document.getElementById("mobileVideoHider");
      var playbutton = document.getElementById("watchTrailerMobile");
      videowrapper.style.display = "block";
      playbutton.style.display = "none";
      this.videoplayer.current.play();
      window._vis_opt_queue = window._vis_opt_queue || [];
      window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
      //this.checkLeadMagnetPopup()
      //var videoiframe = video.getElementsByTagName('iframe')[0];
      //this.setState({videourl: 'https://www.youtube.com/watch?v=eHkBBV7ccsc',playing: 'true'})
      //screenfull.request(findDOMNode(video));
      /*  if (videoiframe.requestFullscreen) {
            videoiframe.requestFullscreen();
        } else if (videoiframe.webkitRequestFullscreen) {
            videoiframe.webkitRequestFullscreen();
        } else if (videoiframe.mozRequestFullScreen) {
            videoiframe.mozRequestFullScreen();
        } else if (videoiframe.msRequestFullscreen) {
            videoiframe.msRequestFullscreen();
        }*/
    }

    playVideoDesktop(){
          var videowrapper = document.getElementById("desktopVideoHider");
          var playbutton = document.getElementById("watchTrailerDesktop");
          var bg = document.getElementsByClassName("fullscreen-bg")[0];
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          window._vis_opt_queue = window._vis_opt_queue || [];
          window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
          //this.checkLeadMagnetPopup()
          /*if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            }*/
    }

    componentDidMount () {

        window.review_token = 'dZPUCxEYX3VA3KQQDRKFJxrGS5YlM5joSy7w7qfrKA43Dv7h4H|BKdZ6EZaS8fhOR7A60WD4yfaEMtJMuDRkVgPJ3Qlo7oZB6x3Ii';
        window.review_target = "review-container";

        const script = document.createElement("script");

        script.src = "https://reviewsonmywebsite.com/js/embed.js";
        //script.async = true;

        document.body.appendChild(script);

        // hotjar tracking
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1024291,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

    render(){
        return(
        <article id="main">
            <Helmet>
                <style></style>
                <meta charSet="utf-8" />
                <title>Top Team Building Virtual Reality Event in Ontario | Mirage VR</title>
                <meta name="description" content="Looking for a unique and engaging way to bring your colleagues together in a team building exercise they won't forget? Turn to Mirage VR for a fully immersive team building VR experience that your entire team will be able to enjoy."/>
                <link rel="canonical" href="https://www.miragevr.ca/teambuilding"/>
            </Helmet>

            {!this.state.mobile &&
            <div className="fullscreen-bg">
                <video id="background-video" className="background-video" src={require("../videos/10secteambuilding.mp4")} type="video/mp4" muted loop autoplay=""/>
                <div className="fullscreen-overlay video-overlay-diagonal"></div>
                <div className="fullscreen-overlay frame-overlay"></div>
            </div>
            }
            {!this.state.mobile &&
            <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                <div className="inner">
                <div className="likeh1"><span className="mirage-teal">Virtual Reality</span> Team Building
                </div>
                    <p>
                        A New Way, A <span className="mirage-teal">Better</span> Way
                    </p>
                </div>
                <ul className="actions">
                        <li><div>
                                <div id="desktopVideoHider">
                                 <video ref={this.videoplayer} id="desktopTrailerVideo" controls type="video/mp4" src={require("../videos/teambuildingtrailer.mp4")} preload="metadata"/>
                                </div>
                                <img id="watchTrailerDesktop" src={require("../images/playbutton.png")} onClick={this.playVideoDesktop} className="small"/>
                        </div></li>
                </ul>
            </section>
            }
            {this.state.mobile &&
            <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: ninetyheight}}>
                <div className="landing2-bg" style={imgDiv}>  
                    <div style={overlay}>
                        <img id="landingimage" className="lazy" src={require("../images/teambuilding-bg2.jpg")} />
                    </div>                    
                </div>
                <div id="bannertext">
                    <div className="bannertoptext">
                    <div className="likeh1"><span className="mirage-teal">Virtual Reality</span> Team Building
                    </div>
                    </div>
                    <div id="playvidbutton">
                            <div id="mobileVideoHider">
                                <video ref={this.videoplayer} id="mobileTrailerVideo" controls type="video/mp4" src={require("../videos/teambuildingtrailer.mp4")} preload="metadata"/>
                            </div>     
                            <img id="watchTrailerMobile" 
                                src={require("../images/playbutton.png")} 
                                onClick={this.playVideoMobile} 
                            className="small" />                  
                    </div>
                    <div className="bannerbottomtext">
                    <p id="landingdescription">
                        A New Way, <span className="mirage-teal">A Better Way</span>
                    </p>
                    </div>
                </div>
            </section>
            }


            <section class="wrapper style2">
                <div class="inner">
                    <h1>Virtual Reality Team Building Events</h1>
                    <br/>
                    <p>Some team building activities are fun, but do not create long-lasting benefits. An AMAZING team building activity
                    effortlessly improves coordination and chemistry between team members. Using virtual reality
                    technology, we've integrated these benefits into the most engaging activity you will ever experience.
                    </p>
                    <a id="gamepage-book" className="button small special" href="#bookteambuilding" alt="" >Book Now!</a>
                </div>
            </section>
            <section id="two" className="wrapper alt style2">
                <section className="spotlight">
                    <div className="image"><img src={require("../images/boredteambuilding.jpg")}/></div><div className="content">
                        <div className="likeh3">Stop pretending to care</div>
                        <br/>
                        <p>
                        What do 90% of team building activities have in common? Participants mindlessly going through the motions. The activity
                        immediately forgotten afterwards. The missing element? Immersion. 
                        </p>
                        <p>
                        With virtual reality, team members are thrust into a world beyond their wildest dreams, 
                        where they must team up as heroes. This results in an unmatched level of engagement, creating
                        truly unforgettable memories.
                        </p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src={require("../images/teambuildingvr.jpg")}/></div><div className="content">
                        <div className="likeh3">Worried they won't enjoy it?</div>
                        <br/>
                        <p>
                         We've designed our experiences to suit a vast array of different people, with different interests and hobbies. 
                         Whether they want to dance, battle, or explore a new dimension, there is something for everyone.
                         For teams as small as 5 to as large as 40.
                        </p>
                    </div>
                </section>
            </section>

            <section id="one" className="wrapper style3 special">
                <div class="inner">
                    <h2>Free-Roaming VR Makes For Great Team Building</h2>
                    <br/>
                    <p>
                        At Mirage VR, team members share a large physical space, where they can see, hear, and touch each other.
                        In a world larger than life, they walk with their own feet and interact with their own hands. 
                        This is both intuitive and immersive, so team members can immediately cut straight to what matters most - 
                        working together in an engaging and meaningful way.
                    </p>
                </div>
            </section>

            <section id="two" className="wrapper alt style3">
                <section className="spotlight">
                    <div className="image"><img src={require("../images/unique.jpg")}/></div><div className="content">
                    <div className="likeh3">Completely Unique Experience</div>
                    <br/>
                    <p>Mirage VR is the first and only destination in Canada to offer free-roam multiplayer VR experiences. Players can physically interact with each other while fully immersed in a world beyond their dreams.</p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src={require("../images/teamwork.jpg")}/></div><div className="content">
                    <div className="likeh3">Adrenaline-Fueled Teamwork</div>
                    <br/>
                    <p>Players are trapped in a fantasy world, and must rely on teamwork, intellect, and dexterity to survive. Our experiences
                    test both mind and body - do you have what it takes?</p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src={require("../images/memories.jpg")}/></div><div className="content">
                    <div className="likeh3">Memories that last</div>
                    <br/>
                    <p>Whether it is sailing through the ocean, or facing a fire-breathing dragon, hyper reality may be temporary, but the memories never fade.</p>
                    </div>
                </section>
            </section>

            <section id="one" className="wrapper style2 special">
                <div class="inner">
                    <h2>Augmented Reality Team Building Activities</h2>
                    <br/>
                    <p>
                        We offer a variety of different team building activities. Whether its fantasy, shooting, or puzzle solving,
                        we have something for everyone! 
                    </p>
                </div>
            </section>

            <section id="two" className="wrapper alt style2">
                <section className="spotlight">
                    <div className="image"><img src={require("../images/mixed-reality1.jpg")}/></div><div className="content">
                    <div className="likeh3">Sherwood Forest</div>
                    <br/>
                    <p>Participants enter into a fantasy world, and become one of 4 heroes: Warrior, Magician, Archer, or Healer, each possessing
                       unique powers granted by the gods. Participants must coordinate and communicate in order to defend a magical forest from 
                       monsters that threaten to destroy it.
                    </p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src={require("../images/ktane.jpg")}/></div><div className="content">
                    <div className="likeh3">Keep Talking and Nobody Explodes</div>
                    <br/>
                    <p>You wake up and discover that you are locked in a room with a bomb! Carefully communicate with your teammates to figure out
                    how to defuse it, before you are blown to smithereens! </p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src={require("../images/zombie.jpg")}/></div><div className="content">
                    <div className="likeh3">Arizona Sunshine</div>
                    <br/>
                    <p>You and your teammates find yourself in a post-apocalyptic world, overrun by zombies! Buckle up, get your
                    weapons ready, and work together to survive the onslaught! </p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src={require("../images/tiltbrush.jpg")}/></div><div className="content">
                    <div className="likeh3">VR Pictionary</div>
                    <br/>
                    <p>Put your creativity to the test in a game of Pictionary, but in VR! The entire room is your canvas and you are only limited by your own imagination.</p>
                    </div>
                </section>
            </section>

            <ClientLogos/>

            <section className="wrapper style2 special">
                <div className="inner">
                    <header className="major">
                        <div className="likeh2">What to expect</div>
                    </header>
                    <ul className="features">
                        <li className="icon fa-retweet">
                            <div className="likeh3">Rotate between multiple Experiences</div>
                        </li>
                        <li className="icon fa-users">
                            <div className="likeh3">Work together to accomplish missions</div>
                        </li>
                        <li className="icon fa-trophy">
                            <div className="likeh3">Compete for top scoring team</div>
                        </li>
                        <li className="icon fa-heart-o">
                            <div className="likeh3">Complimentary Party Room</div>
                        </li>
                    </ul>
                </div>
            </section>


            <a id='rates' href='#rates'></a>

            <section className="pricing wrapper style5 special">
                <div className="inner">
                    <header className="major">
                        <div className="likeh2">Experiences</div>
                    </header>
                </div>
            </section>

            <ExperienceChooser category="TeamBuilding" groupSize="1to40" hideCategory="true"/>
            
            <a id="bookteambuilding"></a>
            <section className="pricing wrapper style2">
                <div className="inner">

                        <div id="message" className="inner fit vertical">
                            <h2>Plan Your Virtual Reality Team Building Experience Today!</h2>
                                <br/>
                                <p>
                                    Every team building event is different, and Mirage VR will work with you to plan your corporate event. Whether
                                    you want to come to our facility, or for us to come to you, we will work to accommodate you.
                                    Simply fill out the form below, and a representative will respond within 24 hours. 
                                    If accommodation is needed for special events (such as booking the space for an entire afteroon), simply include that in the message.
                                </p>
                            <Form tag={'teambuilding'}
                            />
                        </div>
                </div>
            </section> 
            <section id="reviews" className="wrapper style5" >
                    <div className="container inner">
                        <div id="review-container" /> 
                    </div>
            </section>
       
    </article>
        )
    }
};

