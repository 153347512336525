import React from 'react'

import ThankYouModal from '../components/modals/ThankYouModal';
import ErrorModal from '../components/modals/ErrorModal';

export default class ContactForm extends React.Component {
    constructor(props){
        super(props);

        //state
        this.state = {
            name: '',
            organization: '',
            email: '',
            message: '',
            messagePlaceholder: 'Leave us a message and we will get back to you very soon!',
            location: '',
            showThankYouModal: false,
            showErrorModal: false,
            showInquiryType: true,
            showOrganization: false,
            showLocation: false,
            showEventType: false,
            showGroupSize: false,
            inquiryType: '',
            eventType: '',
            groupSize: ''
        };

        if (this.props.tag == "feedback") {
            this.state.showLocation = true;
            this.state.showInquiryType = false;
            this.state.messagePlaceholder = 'Let us know how we can be better!';
            this.state.inquiryType = "feedback";
        } else if (this.props.tag == "feedbackV2") {
            this.state.showLocation = true;
            this.state.showInquiryType = false;
            this.state.messagePlaceholder = 'Sorry about the unsatisfactory experience, please let us know how we can improve!';
            this.state.inquiryType = "feedbackV2";
        } else if (this.props.tag == "birthday") {
            this.state.showInquiryType = false;
            this.state.showLocation = true;
            this.state.showEventType = false;
            this.state.messagePlaceholder = 'Please include the date, time and the approximate number of participants you would like to book for.';
            this.state.eventType = "birthday";
            this.state.inquiryType = "booking";
        } else if (this.props.tag == "teambuilding") {
            this.state.showInquiryType = false;
            this.state.showOrganization = true;
            this.state.showLocation = true;
            this.state.showEventType = false;
            this.state.messagePlaceholder = 'Please include the date, time and the approximate number of participants you would like to book for.';
            this.state.eventType = "teambuilding";
            this.state.inquiryType = "booking";
        } else if (this.props.tag == "partypackage") {
            this.state.showInquiryType = false;
            this.state.showOrganization = false;
            this.state.showLocation = true;
            this.state.showEventType = true;
            this.state.inquiryType = "booking";
        }

        //Binding Functions
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleLocationChanged = this.handleLocationChanged.bind(this);
        this.handleInquiryTypeChanged = this.handleInquiryTypeChanged.bind(this);
        this.handleEventTypeChanged = this.handleEventTypeChanged.bind(this);
        this.handleGroupSizeChanged = this.handleGroupSizeChanged.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        //Modal
        this.exitModal = this.exitModal.bind(this);
        this.openModal = this.openModal.bind(this);

    }

    //Modal
    exitModal(){
        this.setState({showThankYouModal: false});
        this.setState({showErrorModal: false});
        this.setState({name: ''});
        this.setState({organization: ''});
        this.setState({email: ''});
        this.setState({message: ''});
    }
    
    openModal(){
        if(this.state.name&&this.state.email&&this.state.message){
            this.setState({showThankYouModal: true});
        }
    }

    //Form update
    handleNameChange(e){
        this.setState({name: e.target.value});
    }

    handleOrganizationChange(e){
        this.setState({organization: e.target.value});
    }

    handleEmailChange(e){
        this.setState({email: e.target.value});
    }

    handleMessageChange(e){
        this.setState({message: e.target.value});
    }

    handleLocationChanged(e) {
        this.setState({location: e.target.value});
    }

    handleInquiryTypeChanged(e) {
        this.state.inquiryType = e.target.value;
        //console.log("Changed inquiry type to " + inquiryType);
        if (this.state.inquiryType == "booking") {
            this.setState({
                showLocation: true,
                showEventType: true,
                showOrganization: false,
                showGroupSize: false
            });
        } else if (this.state.inquiryType == "feedback") {
            this.setState({
                showLocation: true,
                showEventType: false,
                showOrganization: false,
                showGroupSize: false 
            });
        } else if (this.state.inquiryType == "business" ) {
            this.setState({
                showLocation: false,
                showEventType: false,
                showOrganization: true,
                showGroupSize: false 
            });
        } else if (this.state.inquiryType == "franchise") {
            window.location.href = '/franchise';
        } else {
            this.setState({
                showLocation: false,
                showEventType: false,
                showOrganization: false,
                showGroupSize: false 
            });
        }
    }

    handleEventTypeChanged(e) {
        this.state.eventType = e.target.value;
        if (this.state.eventType == "others") {
            // show groupSize chooser
            this.setState({showGroupSize: true});
        } else {
            this.setState({showGroupSize: false});
        }
    }

    handleGroupSizeChanged(e) {
        this.state.groupSize = e.target.value;
    }


    handleSubmit(e){
        e.preventDefault();
        var name = this.state.name.trim();
		var email = this.state.email.trim();
        var message = this.state.message.trim();
        var organization = this.state.organization.trim();
        var location = this.state.location.trim();
        var inquiryType = this.state.inquiryType.trim();
        var lastURL = window.location.href;
        //console.log({name},{email},{message},{organization});
        var search = document.location.search;
        var tag = this.props.tag;

        // send name, email, organization, inquiryType, eventType, groupSize, location, message
        $.ajax({
			url: '/inquire',
			type: 'POST',
			data: {
				name: name,
				email: email,
				message:message,
				organization: organization,
                urlParams: search,
                location: location,
                lastURL: lastURL,
                tag: tag,
                inquiryType: inquiryType
			},
			success: function(data) {
                this.setState({data: data});
                this.setState({showModal: false, showThankYouModal: true});

                this.sendGoogleAnalyticsEvent(lastURL);
			}.bind(this),
			error: function (err)
            { console.log(err.responseText);
                this.setState({showModal: false, showErrorModal: true});

            }.bind(this)
        
        });

        // Hash to avoid PII.
        var userID = window.stringHash(email);
        ga('set', 'dimension1', userID);
        ga('set', 'userId', userID);
        mixpanel.alias(email);
        mixpanel.track('Submit inquiry');
    }

    sendGoogleAnalyticsEvent(lastURL) {
        if (this.state.inquiryType != "booking") {
            // no need for any GA event
            return;
        }
        var label = "";
        if (this.state.eventType=="birthday") {
            label = "small";
        } else if (this.state.eventType == "teambuilding") {
            label = "medium";
        } else if (this.state.eventType == "corporate") {
            label = "large";
        } else if (this.state.eventType == "others") {
            label = this.state.groupSize;
        }
        if (lastURL.indexOf("miragevr.ca") >= 0) { //production
	    fbq('trackCustom', label)
            ga('send', 'event', 'form', 'submit', label);
        } else { // testing
            console.log("Google Analytics Event: form-submit-" + label);
        }
    }

    render(){
        return(
            <div>
                <form id="messageForm" onSubmit={this.handleSubmit} method="post" action="#">
                    <div className="row uniform" >
                            <label className="col-12 col-md-6">
                                <input 
                                type="text" 
                                id="name" 
                                name="name" 
                                placeholder="Name" 
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                required
                                />
                            </label>

                            <label class="col-12 col-md-6">
                                <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Email" 
                                value={this.state.email} 
                                onChange={this.handleEmailChange} 
                                required
                                />
                            </label>

                        { this.state.showInquiryType &&
                            <label className="col-12 col-md-6">
                                <select name="inquiryType" id="inquiryType" onChange={this.handleInquiryTypeChanged} >
                                    <option value="">- Inquiring About -</option>
                                    <option value="feedback">Customer Feedback</option>
                                    <option value="booking">Booking an event</option>
                                    <option value="franchise">Franchise Inquiry</option>
                                    <option value="business">Business Inquiry</option>
                                    <option value="others">Others</option>
                                </select>                                
                            </label>
                        }

                        { this.state.showOrganization &&
                            <label className="col-12 col-md-6">
                                <input 
                                type="text" 
                                id="organization" 
                                name="organization" 
                                placeholder="Oragnization (Opt)" 
                                value={this.state.organization} 
                                onChange={this.handleOrganizationChange} 
                                />
                            </label>
                        }
                    
                            { this.state.showLocation && 
                                <label className="col-12 col-md-6">
                                    <select name="demo-location" id="location" onChange={this.handleLocationChanged} >
                                        <option value="">- Location -</option>
                                        <option value="Kitchener">Kitchener</option>
                                        <option value="Mississauga">Mississauga</option>
                                        <option value="Burlington">Burlington</option>
                                    </select>                                
                                </label>
                            }
                            
                            { this.state.showEventType && 
                                <label className="col-12 col-md-6">
                                    <select name="eventType" id="eventType" onChange={this.handleEventTypeChanged} >
                                        <option value="">- Event Type -</option>
                                        <option value="birthday">Birthday Party</option>
                                        <option value="teambuilding">Team Building</option>
                                        <option value="corporate">Corporate Event (30+ people)</option>
                                        <option value="others">Others</option>
                                    </select>                                
                                </label>
                            }

                            { this.state.showGroupSize && 
                                <label className="col-12 col-md-6">
                                    <select name="groupSize" id="groupSize" onChange={this.handleGroupSizeChanged} >
                                        <option value="">- Group Size -</option>
                                        <option value="small">Less than 10</option>
                                        <option value="medium">10 - 30</option>
                                        <option value="large">More than 30</option>
                                    </select>                                
                                </label>
                            }
                            <label className="col-12">
                                <textarea 
                                type="message" 
                                id="message" 
                                name="message" 
                                rows="6" 
                                placeholder={this.state.messagePlaceholder} 
                                value={this.state.message} 
                                onChange={this.handleMessageChange} 
                                />
                            </label>
                            <p id="formError" className="hide" style={{color:"red"}}>invalid </p>
                    </div>
                    <div className="row uniform">
                        <div className="col-12">
                            <ul className="actions">
                                <li >
                                    <input 
                                    /*onClick={this.openModal}*/ 
                                    id="sendMessage" 
                                    type="submit" 
                                    value="Send Message" 
                                    className="special"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
                
                <ThankYouModal
                    showModal={this.state.showThankYouModal}
                    exitModal={this.exitModal}
                />
                <ErrorModal
                    showModal={this.state.showErrorModal}
                    exitModal={this.exitModal}
                />
            </div>
        );
    }
}

