import React from 'react'

import ThankYouModal from '../components/modals/ThankYouModal';
import ErrorModal from '../components/modals/ErrorModal';

export default class EmailSubscribeForm extends React.Component {
    constructor(props){
        super(props);
        //inputs
        this.showOrganization= true;
        this.organizationText= ' ';
        this.messageText=' ';

        //state
        this.state = {
            firstname: '',
            lastname: '',
            organization: '',
            email: '',
            message: '',
            showThankYouModal: false,
            showErrorModal: false
        };

        //Binding Functions
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        //Modal
        this.exitModal = this.exitModal.bind(this);
        this.openModal = this.openModal.bind(this);

    }

    //Modal
    exitModal(){
        this.setState({showThankYouModal: false});
        this.setState({showErrorModal: false});
        this.setState({name: ''});
        this.setState({organization: ''});
        this.setState({email: ''});
        this.setState({message: ''});
    }
    
    openModal(){
        if(this.state.name&&this.state.email&&this.state.message){
            this.setState({showThankYouModal: true});
        }
    }

    //Form update
    handleFirstNameChange(e){
        this.setState({firstname: e.target.value});
    }

    handleLastNameChange(e){
        this.setState({lastname: e.target.value});
    }

    handleOrganizationChange(e){
        this.setState({organization: e.target.value});
    }

    handleEmailChange(e){
        this.setState({email: e.target.value});
    }

    handleMessageChange(e){
        this.setState({message: e.target.value});
    }

    handleSubmit(e){
        e.preventDefault();
        var firstName = this.state.firstName.trim();
        var lastName = this.state.lastName.trim();
		var email = this.state.email.trim();
        var message = this.state.message.trim();
        var organization = this.state.organization.trim();
        //console.log({name},{email},{message},{organization});
        var search = document.location.search;

        $.ajax({
			url: '/subscribe/visitor',
			type: 'POST',
			data: {
				firstName: firstName,
                lastName: lastName,
				email: email,
				//message:message,
				//organization: organization,
                urlParams: search
			},
			success: function(data) {
                this.setState({data: data});
				this.setState({showThankYouModal: true});
			}.bind(this),
			error: function (err)
            { console.log(err.responseText);
                this.setState({showErrorModal: true});
            }.bind(this)
        
        });

        // Hash to avoid PII.
        var userID = window.stringHash(email);
        ga('set', 'dimension1', userID);
        ga('set', 'userId', userID);
        mixpanel.alias(email);
        mixpanel.track('Submit inquiry');
    }

    render(){
        return(
            <div>
                <form id="messageForm" onSubmit={this.handleSubmit} method="post" action="#">
                    <div className="row uniform" >
                        <label className="col-12 col-md-4">
                            <input 
                            type="text" 
                            id="firstname" 
                            name="firstname" 
                            placeholder="First Name" 
                            value={this.state.firstname}
                            onChange={this.handleNameChange}
                            required
                            />
                        </label>
                        <label className="col-12 col-md-4">
                            <input 
                            type="text" 
                            id="lastname" 
                            name="lastname" 
                            placeholder="Last Name" 
                            value={this.state.lastname}
                            onChange={this.handleNameChange}
                            required
                            />
                        </label>
                        <label class="col-12 col-md-4">
                                <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Email" 
                                value={this.state.email} 
                                onChange={this.handleEmailChange} 
                                required
                                />
                            </label>
                    </div>
                    <div className="row uniform">
                        <div className="col-12 col-md-8">
                            <ul className="actions">
                                <li >
                                    <input 
                                    /*onClick={this.openModal}*/ 
                                    id="sendMessage" 
                                    type="submit" 
                                    value="Subscribe" 
                                    className="special"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
                
                <ThankYouModal
                    showModal={this.state.showThankYouModal}
                    exitModal={this.exitModal}
                />
                <ErrorModal
                    showModal={this.state.showErrorModal}
                    exitModal={this.exitModal}
                />
            </div>
        );
    }
}


/*
<form id="messageForm" method="post" action="#">
	<div class="row uniform">
			<div class="6u 12u$(xsmall)">
					<input type="text" name="name" id="name" value="" placeholder="Name" style={{marginRight:'100px'}} required/>
			</div>
		    <div class="6u$ 12u$(xsmall)">
					<input type="text" name="organization" id="organization" value="" placeholder="Organization(Optional)"/>
			</div>
	</div>
	<div class="row uniform">
			<div class="12u$ 12u$(xsmall)">
					<input type="email" name="email" id="email" value="" placeholder="Email" required/>
			</div>
	</div>
	<div class="row uniform">
		    <div class="12u$">
				<textarea name="message" id="message" placeholder="Leave us a message and we will get back to you very soon!" rows="6"></textarea>
			</div>
			<p id="formError" class="hide" style="color:red">invalid </p>
	</div>
	<div class="row uniform">
		<div class="12u$">
			<ul class="actions">
				<li><input id="subscribe" type="submit" value="subscribe" class="special"/></li>
			</ul>
		</div>
	</div>
</form>
*/
