import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Testimonials from '../components/Testimonials';

//this isn't scripted on index instead it is installed as a dependancy
import LeadMagnetModal from '../components/modals/LeadMagnetModal';
import Cookies from 'universal-cookie';
import LazyLoad from 'react-lazyload';
import BookModal from '../components/modals/BookModal';
import AsFeaturedOnLogos from '../components/AsFeaturedOnLogos';

const height = String($(window).height()*(1)) + "px";
const ninetyheight= String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.5)'
    
}
const img = {
   width: imgWidth,
   height: imgHeight,
   opacity: '0.65'
}

export default class HomePage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showVideo: false,
            showLeadMagnet: false,
            showBookModal: false,
            mobile: ($(window).width() <= 763)? true : false,
            portrait: ($(window).width() <= 500)? true : false,
            videourl: null,
            playing: true,
            leadMagnetPoppedUp: false,
        }

        this.openVideoModal = this.openVideoModal.bind(this);
        this.exitVideoModal = this.exitVideoModal.bind(this);
        this.openLeadMagnetModal = this.openLeadMagnetModal.bind(this);
        this.exitLeadMagnetModal = this.exitLeadMagnetModal.bind(this);
        this.checkLeadMagnetPopup = this.checkLeadMagnetPopup.bind(this);
        this.checkLeadMagnetPopupLongWait = this.checkLeadMagnetPopupLongWait.bind(this);
        this.playVideoMobile = this.playVideoMobile.bind(this);
        this.playVideoDesktop = this.playVideoDesktop.bind(this);
        this.openBookModal = this.openBookModal.bind(this);
        this.exitBookModal = this.exitBookModal.bind(this);
        this.trackSessionDuration = this.trackSessionDuration.bind(this);
        this.trackSessionDurationAfterDelay = this.trackSessionDurationAfterDelay.bind(this);
        this.videoplayer = React.createRef();
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 763){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
            if($(window).width() <= 500){
                this.setState(()=> ({portrait: true}));
            }
            else {
                 this.setState(()=> ({portrait: false}));
         }
        });
        //$("div.fullscreen-bg").css('width', String($(window).width())+'px');
    }

    openVideoModal(){
        mixpanel.track("Video play");
        this.setState(()=> ({showVideo: true}));

    }
    
    exitVideoModal(){
        mixpanel.track('Video close');
        this.setState(()=> ({showVideo: false}));
    }

    playVideoMobile(){
          var videowrapper = document.getElementById("mobileVideoHider");
          var playbutton = document.getElementById("watchTrailerMobile");
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          window._vis_opt_queue = window._vis_opt_queue || [];
          window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
          //this.checkLeadMagnetPopup()
          //var videoiframe = video.getElementsByTagName('iframe')[0];
          //this.setState({videourl: 'https://www.youtube.com/watch?v=eHkBBV7ccsc',playing: 'true'})
          //screenfull.request(findDOMNode(video));
          /*  if (videoiframe.requestFullscreen) {
                videoiframe.requestFullscreen();
            } else if (videoiframe.webkitRequestFullscreen) {
                videoiframe.webkitRequestFullscreen();
            } else if (videoiframe.mozRequestFullScreen) {
                videoiframe.mozRequestFullScreen();
            } else if (videoiframe.msRequestFullscreen) {
                videoiframe.msRequestFullscreen();
            }*/
    }

    playVideoDesktop(){
          var videowrapper = document.getElementById("desktopVideoHider");
          var playbutton = document.getElementById("watchTrailerDesktop");
          var bg = document.getElementsByClassName("fullscreen-bg")[0];
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          window._vis_opt_queue = window._vis_opt_queue || [];
          window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
          //this.checkLeadMagnetPopup()
          /*if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            }*/
    }


    openLeadMagnetModal(){
        if (this.state.leadMagnetPoppedUp == false && ((this.videoplayer.current != null) && (this.videoplayer.current.paused || this.videoplayer.current.ended))) {
            let cookies = new Cookies();
            cookies.set('visitedbefore', true, { path: '/' });
            this.setState(()=> ({leadMagnetPoppedUp: true}));
            this.setState(()=> ({showLeadMagnet: true}));
        }
    }

    exitLeadMagnetModal(){
        this.setState(()=> ({showLeadMagnet: false}));
    }

    checkLeadMagnetPopup(){
        let cookies = new Cookies();
        if(this.state.leadMagnetPoppedUp == false && typeof cookies.get('visitedbefore') == 'undefined'){
            setTimeout(this.openLeadMagnetModal, 5000);
            setTimeout(this.checkLeadMagnetPopup, 5000);
        }
    }

    checkLeadMagnetPopupLongWait(){
        /*
        let cookies = new Cookies();
        if(typeof cookies.get('visitedbefore') == 'undefined'){
            setTimeout(this.openLeadMagnetModal, 10000);
        }*/
    }

    trackSessionDurationAfterDelay(){
        setTimeout(this.trackSessionDuration, 60000);
    }

    trackSessionDuration(){
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(201);});
    }

    openBookModal(){
        this.setState(()=> ({showBookModal: true}));
    }

    exitBookModal(){
        this.setState(()=> ({showBookModal: false}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <link rel="canonical" href="https://www.miragevr.ca"/>
                    <meta name='description' content="If you're searching for the perfect VR experience in Ontario for special occasions such as team building events or birthday parties, or simply want to enjoy a unique and fun experience with your friends, visit Mirage VR and book with us today."/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Hyper Reality Experience Centre | Mirage VR"/>
                    <meta property="og:description" content="Canada's first full-body VR destination. Gear up to enter a new dimension where you battle, dance, and explore with friends, family, or team. Book now to start your next adventure!"/>
                    <meta property="og:url" content="https://www.miragevr.ca/"/>
                    <meta property="og:site_name" content="Mirage VR"/>
                    <meta property="og:image" content="https://miragevr.ca/images/hyperrealityexplanation.jpg"/>
                    <meta property="og:image:width" content="1500"/>
                    <meta property="og:image:height" content="1050"/>
                    <meta property="fb:app_id" content="118069268859718"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:description" content="Canada's first full-body VR destination. Gear up to enter a new dimension where you battle, dance, and explore with friends, family, or team. Book now to start your next adventure!"/>
                    <meta name="twitter:title" content="Hyper Reality Experience Centre | Mirage VR"/>
                    <meta name="twitter:site" content="@entermirage"/>
                    <meta name="twitter:image" content="https://miragevr.ca/images/hyperrealityexplanation.jpg"/>
                    <meta name="twitter:creator" content="@entermirage"/>
                    <title>Best Virtual Reality Experience Centre in Ontario | Mirage VR</title>
                </Helmet>
                <LeadMagnetModal
                showModal={this.state.showLeadMagnet}
                exitModal={this.exitLeadMagnetModal}
                />

                
                {this.checkLeadMagnetPopupLongWait()}
                {this.trackSessionDurationAfterDelay()}

                {!this.state.mobile &&
                <div className="fullscreen-bg">
                    <video id="background-video" className="background-video" src={require("../videos/10sectrailer5.mp4")} type="video/mp4" muted loop autoplay=""/>
                    <div className="fullscreen-overlay video-overlay-diagonal"></div>
                    <div className="fullscreen-overlay frame-overlay"></div>
                </div>
                }
                {!this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                    <div className="inner">
                    <h1>Experience The <span className="mirage-teal">Future Of Entertainment</span>
                    </h1>
                        <p>
                            The Best Group Experience On The Planet, Powered by <span className="mirage-teal">Virtual Reality</span>
                        </p>
                    </div>
                    <ul className="actions">
                            <li><div>
                                    <div id="desktopVideoHider">
                                     <video ref={this.videoplayer} id="desktopTrailerVideo" controls type="video/mp4" src={require("../videos/trailer2.mp4")} preload="metadata"/>
                                    </div>
                                    <img id="watchTrailerDesktop" src={require("../images/playbutton.png")} onClick={this.playVideoDesktop} className="small"/>
                            </div></li>
                    </ul>
                </section>
                }
                {this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: ninetyheight}}>
                    <div className="landing2-bg" style={imgDiv}>  
                        <div style={overlay}>
                            <img id="landingimage" className="lazy" src={require("../images/dragonbreath.jpg")} />
                        </div>                    
                    </div>
                    <div id="bannertext">
                        <div className="bannertoptext">
                        <h1>Experience The <span className="mirage-teal">Future Of Entertainment</span>
                        </h1>
                        </div>
                        <div id="playvidbutton">
                                <div id="mobileVideoHider">
                                    <video ref={this.videoplayer} id="mobileTrailerVideo" controls type="video/mp4" src={require("../videos/trailer2.mp4")} preload="metadata"/>
                                </div>     
                                <img id="watchTrailerMobile" 
                                    src={require("../images/playbutton.png")} 
                                    onClick={this.playVideoMobile} 
                                className="small" />                  
                        </div>
                        <div className="bannerbottomtext">
                        <p id="landingdescription">
                            The Best Group Experience On The Planet, Powered by <span className="mirage-teal">Virtual Reality</span>
                        </p>
                        </div>
                    </div>
                </section>
                }

                <AsFeaturedOnLogos/>
                
                <LazyLoad height={600} once offset={100}>
                <section id="two" className="wrapper alt style2">
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/whatisvirtualreality.jpg")} alt="" /></div><div className="content">
                           <h2>Beyond Your Wildest Imagination</h2>
                           <p></p>
                           <p>At Mirage VR, we transport your body and mind into a fantasy world, where you 
                           <span className="mirage-teal"> become the hero</span> of your story. 
                           </p>
                            <p>
                            <a id="gamepage-book" className="button small special" href="/experiences" alt="" >See Experiences</a>
                            </p>
                        </div>
                    </section>
                    <section className="spotlight">
                    <div className="image"><img src={require("../images/groupfun.jpg")} alt="" /></div><div className="content">
                       <h2>Perfect For Every Occasion</h2>
                       <p></p>
                       <p> Our amazing experiences are suitable for all ages. Whether you're looking to host a 
                           birthday party, a team building event, or a fun group activity, 
                           Mirage VR is the best choice. 
                        </p>
                        <p><a href="/birthday" id="gamepage-book" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>Birthdays</a>
                        <a href="/teambuilding" id="gamepage-book" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>Team Building</a>
                        </p>
                    </div>
                    </section>
                </section>
                </LazyLoad>


               <LazyLoad height={400} once offset={100}>
                    <section className="wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                        <div className="inner">
                        <header className="major less-heavy-text">
                                <h2>Explore Magical Worlds, Packed with Adventure, Story, and <span className="mirage-teal">
                                Battle </span>
                                </h2>
                        </header>
                        </div>
                    </section>
                    <section className="wrapper style5">
                        <div className="inner">
                          <section>
                          <div className="row description">
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/butterfly.png")}/>
                              <h3>Breathtaking<br/> Immersion</h3>
                              <p>Explore deep forests and sail in an endless ocean. Let your senses be the guide as you physically explore the edge of your imagination.</p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/swords.png")}/>
                              <h3>Unforgettable<br/> Adventure</h3>
                              <p>Feel the adrenaline rushing through your body as you discover the unexpected and face the impossible. A new adventure awaits, are you ready?</p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/fullbodyvr.png")}/>
                              <h3>Cutting-edge<br/> VR Technology</h3>
                              <p>Our experiences are enabled by a complete motion capture system, customized backpack PCs and top tier VR headsets. <a className="hide" href="" style={{borderBottom: '1px solid'}}>Learn More</a></p>
                            </div>
                          </div>
                          </section>
                        </div>
                      </section>
                </LazyLoad>
               <LazyLoad height={400} once offset={300}>
                    <section className="faq-inline wrapper style7 special no-border" style={{display: 'none', padding: '2em 2em 1em 2em'}}>
                        <div className="inner">
                        <header className="major less-heavy-text">
                                <h1 className="hide">Coming to Kitchener Waterloo</h1>
                                <h1>Group Experiences where you'll use <span className="mirage-teal">teamwork, intellect and dexterity </span>to survive</h1>                        
                        </header>
                        </div>
                    </section>

                    <section className="wrapper style5 special hide">
                        <h2 className="major">How to get started?</h2>
                        <div className="row">
                            <div className="4u 12u$(medium) align-center" style={{padding:'0px'}}>
                            </div>
                            <div className="4u 12u$(medium) align-center" style={{padding:'0px'}}>
                            </div>
                            <div className="4u 12u$(medium) align-center" style={{padding:'0px'}}>
                            </div>
                            </div>
                    </section>

                </LazyLoad>

                <LazyLoad height={600} once offset={400}>
                    <section className="wrapper style2">
                        <div className="inner">
                          <section>
                          <header className="major align-center ">
                          <div className="likeh3">Our Amazing Experiences</div>
                          </header>
                            <div>
                              <div className="row events-occasions">
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/freeroamvrcombopackage"><img className="lazy" src={require("../images/freeroam-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Free-Roam Combo
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">2-5 players</span> </div>
                                    <p> Our most immersive package, where your body is the controller! Explore with your own legs, interact with your own hands, and coordinate as a team to stay alive.
                                    </p>

                                </div>
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/stationaryvrpackage"><img className="lazy" src={require("../images/vrbooth-bg.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Stationary VR
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">2-10 players</span></div>
                                    <p>
                                        Explore over 50 worlds in your private VR station. Whether you like shooting zombies, solving puzzles, or grooving to the rhythm,
                                        we have something for everyone!
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 align-center">  
                                    <a><img className="lazy" src={require("../images/magicdoor-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Best of Both Worlds
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">2-10 players</span></div>
                                    <p>
                                        Completely immerse yourselves in free-roaming virtual reality
                                        and explore over 50 worlds in private VR stations. It really is the best of both worlds.
                                    </p>
                                    </div>
                               </div>
                               <div className="row events-occasions">
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/defendanddefusepackage"><img className="lazy" src={require("../images/defend&defuse-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Defend And Defuse
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">6-10 players</span></div>                                    
                                    <p>
                                        Get ready for the ultimate test of teamwork. Defend a magical forest from the forces of evil,
                                        and work together to defuse a bomb before it blows you to smithereens!
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/partypackage"><img className="lazy" src={require("../images/vrpictionary-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Party Package
                                    </div>  
                                    <div className="likeh4"><span className="mirage-teal">11-20 players</span></div>
                                    <p>Our virtual reality experiences, tailored for parties. Explore deep forests, defuse bombs, and 
                                       play a game of pictionary in virtual reality.
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 align-center">  
                                    <a href="/deluxepartypackage"><img className="lazy" src={require("../images/landingportal-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Deluxe Party Package
                                    </div>  
                                    <div className="likeh4"><span className="mirage-teal">11-40 players</span></div>
                                    <p>The ultimate party package, with access to all of our experiences. Our free-roaming VR arena,
                                       party stations, and private booths are fully yours to enjoy.
                                    </p>
                                </div>
                               </div>
                                <div className="col-12 col-md-12 align-center">
                                    <a href="/experiences" id="gamepage-book" className="button small special" alt=""> See Experiences</a>
                                </div>
                            </div>
                            
                        </section>
                        </div>
                    </section>
                </LazyLoad>

                <LazyLoad height={200} once offset={600}>
                    <Testimonials />
                    <section className="faq-inline wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                        <div className="inner">
                            <header className="major less-heavy-text">
                                    <h1>Have more questions?</h1>
                            </header>
                            <a className="button small" href="/faq">See FAQ</a>
                        </div>
                    </section>
                </LazyLoad>
                <BookModal 
                showModal={this.state.showBookModal}
                exitModal={this.exitBookModal}
                />
            </div>
        )
    }
};

// const img = {
//     backgroundImage: 'url("https://res.cloudinary.com/teepublic/image/private/s--E-fxVegu--/t_Preview/b_rgb:ffffff,c_lpad,f_jpg,h_630,q_90,w_1200/v1515227040/production/designs/2258222_3.jpg")', /*Just for clarrification case*/
//     textAlign: 'center !important',
//     margin: '0 auto !important'
// }
// const imgDiv = {
//     backgroundImage: 'url("https://s33.postimg.cc/b1rse32sv/dragonfire.jpg")',
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'auto',
//     display: 'inline !important',
//     //margin: 'auto !important',
//     position: 'fixed !important',
// }