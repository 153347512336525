import React from 'react';
import Helmet from 'react-helmet';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';


const queryString = require('query-string');
const cookies = new Cookies();

class BookPage extends React.Component {
    constructor(props){
        super(props);
        const search = this.props.location.search;
        const params = queryString.parse(search);

        this.state = {
            show : params['show'],
            quantity : params['quantity']? params['quantity'] : 1,
            appointmentType : params['appointmentType'],
            location : params['location'],
            firstname : params['firstName'],
            lastName : params['lastName'],
            email : params['email'],
            extras : "",
            url : "",
            weekend : false,
            mobile: ($(window).width() <= 763)? true : false,
            hasAllUserInfo: false
        }

        var url = window.location.href;
        //if (url.indexOf('localhost')<0) {
            this.restoreFromCookies();
       // }

       // if appointmen type is not specified, redirect back to experiences. 
        if (!this.state.appointmentType) {
            document.location.href = "/experiences#booknow";
        }

        this.state.hasAllUserInfo = this.state.email && this.state.location;

        if (this.state.hasAllUserInfo) {
            this.saveToCookies();
        }

        if (params['phone']) {
            this.state.extras += "&phone="+params['phone'];
        }       
        if (params['datetime']) {
            this.state.extras += "&datetime="+params['datetime'];
        }
        if (params['certificate']) {
            this.state.extras += "&certificate="+params['certificate'];
        }   

        this.state.url = this.constructURL();

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleLocationChanged = this.handleLocationChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    restoreFromCookies() {
        if (cookies.get('firstName')) {
            this.state.firstName = cookies.get('firstName');
          }
        if (cookies.get('lastName')) {
            this.state.lastName = cookies.get('lastName');
        }
        if (cookies.get('email')) {
            this.state.email = cookies.get('email');
        }
        if (cookies.get('location')) {
            this.state.location = cookies.get('location');
            //console.log("retrieved locaiton from cookies");
        }  
    }

    saveToCookies() {
        //adding too cookies
        var expire = new Date();
        expire.setDate(expire.getDate() + 7);

        if (this.state.firstName) {
            cookies.set("firstName", this.state.firstName, {'expires': expire});
        }
        if (this.state.lastName) {
            cookies.set("lastName", this.state.lastName, {'expires': expire});
        }
        if (this.state.email) {
            cookies.set("email", this.state.email, {'expires': expire});
        }
        if (this.state.location) {
            cookies.set("location", this.state.location, {'expires': expire});
        }
    }

    constructURL() {
        var urlStr = "";
        var packageName = "";
        if (this.state.location == "mississauga" || this.state.location == "kitchener") {
            packageName = this.state.appointmentType;
            if (packageName == "stationaryvr"){
                packageName = "mississauga-stationaryvr";
            }
            this.state.quantity = 1;
        } else {
            packageName = this.state.location + '-' + this.state.appointmentType;
        }
        this.state.extras += this.state.firstName != null ? "&firstName="+ this.state.firstName : "";
        this.state.extras += this.state.lastName != null ? "&lastName=" + this.state.lastName : "";
        this.state.extras += "&email=" + this.state.email;

        if (this.state.location == "kitchener") {
            urlStr = "https://miragevr.as.me/" + packageName + "?quantity="+this.state.quantity+this.state.extras;
        } else if (this.state.location == "mississauga") {
            urlStr = "https://miragevr-2.as.me/" + packageName + "?quantity="+this.state.quantity+this.state.extras;
        } else {
            $('#error').text("Please choose a valid location");
            $('#error').show();        
        }
        return urlStr;
    }

    handleFirstNameChange(e){
        this.setState({firstName: e.target.value});
    }

    handleLastNameChange(e){
        this.setState({lastName: e.target.value});
    }

    handleEmailChange(e){
        this.setState({email: e.target.value});
    }

    handleLocationChanged(e) {
        if (e.target.value == "mississauga" || e.target.value == "kitchener") {
            $('#error').hide();
            $('#emailInput').show();
            $('#submitButton').show();
            this.setState({location: e.target.value});
        } else {
            $('#submitButton').hide();
            $('#error').text("Sorry, Please choose a valid location");
            $('#error').show();
            this.setState({location: e.target.value});
        } 
    }

    handleSubmit(e){
        e.preventDefault();

        //check form completition

        this.saveToCookies();

        var showModal = !(this.state.email && this.state.location);
        //console.log("showModal: " + showModal);
        this.setState({hasAllUserInfo: this.state.email && this.state.location});
        this.setState({url: this.constructURL()});

        $.ajax({
            url: '/subscribe/potential',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            type: 'POST',
            data: {
                "email": this.state.email.trim(),
                //"FirstName": this.state.firstName.trim(),
                //"LastName": this.state.lastName.trim(),
                "AppointmentType": this.state.appointmentType.trim(),
                //"Quantity": this.state.quantity,
                //"DateWebsiteVisit": date.toISOString().substring(0,10),
                "location": this.state.location
            },
            success: function(data) {
                //console.log("subscribed");
                //alert("Subscribed");
            },
            error: function(jqXHR, textStatus, err){
                //console.log(jqXHR);
                //alert(jqXHR);
            }
        });
    }

    componentDidMount(){
        var bottomNavHeight = $('#bottomNav').height();
        var padding = 10;
        $( "#weekday-switch" ).css('bottom', (bottomNavHeight+padding)+"px");
    }

    render(){
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bookings | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/book"/>
                </Helmet>

                <article id="main"  >
                    <iframe id='acuity-iframe' src={this.state.url} width='100%' height={$(window).height()} frameBorder='0'></iframe>
                    <script src='https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js' type='text/javascript'/>
                </article>

                <Modal
                isOpen={!this.state.hasAllUserInfo}
                onRequestClose= {this.exitModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className="modal"
                overlayClassName="overlay"
                >
                <div id="book" className="iziModal-wrapper" style={{padding: '0 !important'}}>
                    <div className="inner fit vertical align-center">
                        <h2>Choose Location</h2>
                        <div className="iziModal-content" style={{padding: '1em 0'}}>
                            <form onSubmit={this.handleSubmit} method="post" action="#" style={{marginBottom:"0px"}}>
                                {
                                    /**
                                     * 
                                        <div class="row uniform">
                                            <label className="col-12 col-sm-6">
                                                <input type="text" name="firstName" id="firstName" value={this.state.firstName} onChange={this.handleFirstNameChange} placeholder="First Name" required/>
                                            </label>
                                            <label className="col-12 col-sm-6">
                                                <input type="text" name="lastName" id="lastName" value={this.state.lastName} onChange={this.handleLastNameChange} placeholder="Last Name" required/>
                                            </label>
                                        </div>
                                     */
                                }

                                <div class="row uniform">
                                    <label className="col-12">
                                        <select name="demo-location" id="location" value={this.state.location} onChange={this.handleLocationChanged} placeholder="Location" >
                                            <option disabled selected hidden value="">- Location -</option>
                                            <option value="kitchener">Kitchener</option>
                                            <option value="mississauga">Mississauga</option>
                                        </select>                                     
                                    </label>
                                </div>

                                <div id="emailInput" class="row uniform" style={{display:"none"}}>
                                    <label className="col-12">
                                        <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email" required/>
                                    </label>
                                </div>
                            
                                <div class="row uniform">
                                    <div className="col-12">
                                        <ul class="actions">
                                            <li><input id="submitButton" type="submit" value="See Availability" class="special"/></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="row uniform" >
                                    <div className="col-12">
                                        <p id="error" style={{display:"none"}}></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </Modal>
            </div>
        )
    }
}


export default BookPage;

//6-10 weekday special + fri/sat
// <iframe src='https://miragevr.as.me/?appointmentType=category:6-10+Players&quantity=6"+extras+"' width='100%' height='800' frameBorder='0'></iframe>

//1-5 normal
//<iframe src='https://miragevr.as.me/schedule.php?appointmentType=category%3A1-5+Players&quantity=2"+extras+"' width='100%' height='800' frameBorder='0'></iframe>

//11-15 
//<iframe src='https://miragevr.as.me/?appointmentType=category:11-15+Players&quantity=11"+extras+"' width='100%' height='800' frameBorder='0'></iframe>

//16-20
//<iframe src='https://miragevr.as.me/?appointmentType=category:16-20+Players&quantity=16"+extras+"' width='100%' height='800' frameBorder='0'></iframe>

//gift card
//<iframe src='https://app.acuityscheduling.com/catalog.php?owner=13390492' width='100%' height='800' frameBorder='0'></iframe>

//1-5, 6-10 & 6-10 deprecated
//<iframe src='https://app.acuityscheduling.com/schedule.php?owner=13390492' width='100%' height='800' frameBorder='0'></iframe>



/* SAVINGS
if (today.getDay() != 5)
            {
                $('#promoMessage').html("Book on Weekdays or Sundays for $5 off each player!")
                bookingIframeCode5P = " <iframe src='https://miragevr.as.me/?appointmentType=6107048&certificate=G3RP24&quantity=2"+extras+"' width='100%' height='800' frameBorder='0'></iframe><script src='https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js' type='text/javascript'/> ";
                bookingIframeCode10P = " <iframe src='https://miragevr.as.me/?appointmentType=6107078&certificate=G3RP24&quantity=6"+extras+"' width='100%' height='800' frameBorder='0'></iframe><script src='https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js' type='text/javascript'/> ";
            }/* 
            else if (today.getDay() == 4) {
                $('#promoMessage').html("Book on Thursday for $10 off!")
                bookingIframeCode5P = " <iframe src='https://miragevr.as.me/?appointmentType=6107048&quantity=2"+extras+"' width='100%' height='800' frameBorder='0'></iframe><script src='https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js' type='text/javascript'/> ";
                bookingIframeCode10P = " <iframe src='https://miragevr.as.me/?appointmentType=6107078&quantity=6"+extras+"' width='100%' height='800' frameBorder='0'></iframe><script src='https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js' type='text/javascript'/> ";
            } 
            else {
                $('#promoMessage').html("Book on Sundays for $5 off each player!")
                bookingIframeCode5P = " <iframe src='https://miragevr.as.me/?appointmentType=6150118&quantity=2"+extras+"' width='100%' height='800' frameBorder='0'></iframe><script src='https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js' type='text/javascript'/> ";
                bookingIframeCode10P = " <iframe src='https://miragevr.as.me/?appointmentType=6150128&quantity=6"+extras+"' width='100%' height='800' frameBorder='0'></iframe><script src='https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js' type='text/javascript'/> ";
            }
*/