import React, { Component } from 'react';
import Helmet from 'react-helmet';
import EscapeRoomTestimonials from '../components/EscapeRoomTestimonials';

//this isn't scripted on index instead it is installed as a dependancy
import LeadMagnetModal from '../components/modals/LeadMagnetModal';
import Cookies from 'universal-cookie';
import LazyLoad from 'react-lazyload';
import ClientLogos from '../components/ClientLogos';
import AsFeaturedOnLogos from '../components/AsFeaturedOnLogos';
import ExperienceChooser from '../components/ExperienceChooser';

import BookModal from '../components/modals/BookModal';

const height = String($(window).height()*(1)) + "px";
const ninetyheight= String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    paddingTop: '3em',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.5)'
    
}
const img = {
   width: imgWidth,
   height: imgHeight,
   opacity: '0.65'
}

export default class EscapePage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showVideo: false,
            showLeadMagnet: false,
            mobile: ($(window).width() <= 763)? true : false,
            portrait: ($(window).width() <= 500)? true : false,
            videourl: null,
            playing: true,
        }
        this.openVideoModal = this.openVideoModal.bind(this);
        this.exitVideoModal = this.exitVideoModal.bind(this);
        this.openLeadMagnetModal = this.openLeadMagnetModal.bind(this);
        this.exitLeadMagnetModal = this.exitLeadMagnetModal.bind(this);
        this.checkLeadMagnet = this.checkLeadMagnet.bind(this);
        this.playVideoMobile = this.playVideoMobile.bind(this);
        this.playVideoDesktop = this.playVideoDesktop.bind(this);
        this.videoplayer = React.createRef();

        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.showBookModal = this.showBookModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 763){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }  if($(window).width() <= 500){
                this.setState(()=> ({portrait: true}));
            }
            else {
                 this.setState(()=> ({portrait: false}));
         }
        });
        //$("div.fullscreen-bg").css('width', String($(window).width())+'px');
    }

    openVideoModal(){
        this.setState(()=> ({showVideo: true}));

    }
    
    exitVideoModal(){
        this.setState(()=> ({showVideo: false}));
    }

    playVideoMobile(){
          var videowrapper = document.getElementById("mobileVideoHider");
          var playbutton = document.getElementById("watchTrailerMobile");
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          this.checkLeadMagnetPopup()
          //var videoiframe = video.getElementsByTagName('iframe')[0];
          //this.setState({videourl: 'https://www.youtube.com/watch?v=eHkBBV7ccsc',playing: 'true'})
          //screenfull.request(findDOMNode(video));
          /*  if (videoiframe.requestFullscreen) {
                videoiframe.requestFullscreen();
            } else if (videoiframe.webkitRequestFullscreen) {
                videoiframe.webkitRequestFullscreen();
            } else if (videoiframe.mozRequestFullScreen) {
                videoiframe.mozRequestFullScreen();
            } else if (videoiframe.msRequestFullscreen) {
                videoiframe.msRequestFullscreen();
            }*/
    }

    playVideoDesktop(){
          var videowrapper = document.getElementById("desktopVideoHider");
          var playbutton = document.getElementById("watchTrailerDesktop");
          var bg = document.getElementsByClassName("fullscreen-bg")[0];
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          this.checkLeadMagnetPopup()

          /*if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            }*/
    }

    openLeadMagnetModal(){
        if (!this.state.showVideo) {
            let cookies = new Cookies();
            cookies.set('visitedbefore', true, { path: '/' });
            this.setState(()=> ({showLeadMagnet: true}));
        }
    }

    exitLeadMagnetModal(){
        this.setState(()=> ({showLeadMagnet: false}));
    }

    checkLeadMagnet(){
        let cookies = new Cookies();
        if(typeof cookies.get('visitedbefore') == 'undefined'){
            setTimeout(this.openLeadMagnetModal, 5000);
        }
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    showBookModal(){
        this.setState(()=> ({showModal: true}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <link rel="canonical" href="https://www.miragevr.ca/escape"/>
                    <meta name='description' content="Canada's first full-body VR destination. Gear up to enter a new dimension where you battle, dance, and explore with friends, family, or team. Book now to start your next adventure!"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Hyper Reality Experience Centre | Mirage VR"/>
                    <meta property="og:description" content="Canada's first full-body VR destination. Gear up to enter a new dimension where you battle, dance, and explore with friends, family, or team. Book now to start your next adventure!"/>
                    <meta property="og:url" content="https://www.miragevr.ca/"/>
                    <meta property="og:site_name" content="Mirage VR"/>
                    <meta property="og:image" content="https://miragevr.ca/images/hyperrealityexplanation.jpg"/>
                    <meta property="og:image:width" content="1500"/>
                    <meta property="og:image:height" content="1050"/>
                    <meta property="fb:app_id" content="118069268859718"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:description" content="Canada's first full-body VR destination. Gear up to enter a new dimension where you battle, dance, and explore with friends, family, or team. Book now to start your next adventure!"/>
                    <meta name="twitter:title" content="Hyper Reality Experience Centre | Mirage VR"/>
                    <meta name="twitter:site" content="@entermirage"/>
                    <meta name="twitter:image" content="https://miragevr.ca/images/hyperrealityexplanation.jpg"/>
                    <meta name="twitter:creator" content="@entermirage"/>
                    <title>Mirage VR | Virtual Reality Escape Rooms</title>
                </Helmet>
                <LeadMagnetModal
                showModal={this.state.showLeadMagnet}
                exitModal={this.exitLeadMagnetModal}
                />

                
                {/*this.checkLeadMagnet()*/}

                {!this.state.mobile &&
                <div className="fullscreen-bg">
                    <video id="background-video" className="background-video" src={require("../videos/10secdecomposed.mp4")} type="video/mp4" muted loop autoplay=""/>
                    <div className="fullscreen-overlay video-overlay-diagonal"></div>
                    <div className="fullscreen-overlay frame-overlay"></div>
                </div>
                }
                {!this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                    <div className="inner">
                    <h1>Immersive <span className="mirage-teal">Virtual Reality</span> Escape Rooms
                    </h1>
                        <p>You are <span className="mirage-teal">trapped</span> in a virtual world.<br/> can you <span className="mirage-teal">escape</span> alive with friends?</p>
                    </div>
                    <ul className="actions">
                            <li><div>
                                    <div id="desktopVideoHider">
                                     <video ref={this.videoplayer} id="desktopTrailerVideo" controls type="video/mp4" src={require("../videos/decomposedtrailertest.mp4")} preload="metadata"/>
                                    </div>
                                    <img id="watchTrailerDesktop" src={require("../images/playbutton.png")} onClick={this.playVideoDesktop} className="small"/>
                            </div></li>
                    </ul>
                </section>
                }
                {this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: ninetyheight}}>
                    <div className="landing2-bg" style={imgDiv}>  
                        <div style={overlay}>
                            <img id="landingimage" className="lazy" src={require("../images/escapebg.jpg")} />
                        </div>                    
                    </div>
                    <div id="bannertext">
                        <div className="bannertoptext">
                        <h1>Immersive <span className="mirage-teal">Virtual Reality</span> Escape Rooms</h1>
                        </div>
                        <div id="playvidbutton">
                                <div id="mobileVideoHider">
                                 <video ref={this.videoplayer} id="mobileTrailerVideo" controls type="video/mp4" src={require("../videos/decomposedtrailertest.mp4")} preload="metadata"/>
                                </div>     
                                <img id="watchTrailerMobile" 
                                    src={require("../images/playbutton.png")} 
                                    onClick={this.playVideoMobile} 
                                className="small" />                  
                        </div>
                        <div className="bannerbottomtext">
                            <p>You are <span className="mirage-teal">trapped</span> in a virtual world<br/> can you <span className="mirage-teal">escape</span> alive with friends?</p>
                        </div>
                    </div>
                </section>
                }
                
                {
                    /*
                        <AsFeaturedOnLogos/>
                    */
                }


                <article className="wrapper style3 special no-border" style={{padding:'0em 2em 1em 2em'}}>
                    <section>                    
                        <div className="inner">
                            <section style={{margin:'0em 0em 2em 0em'}}>
                                <div className="row events-occasions" >
                                    <div className="col-6 col-md-3 align-center">
                                        <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                            2-5
                                            <br/>
                                            <br/>
                                            <span className="halfem">Players</span>
                                        </p>
                                    </div>
                                    <div className="col-6 col-md-3 align-center">
                                        <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                            60
                                            <br/>
                                            <br/>
                                            <span className="halfem">minutes</span>
                                        </p>
                                    </div>
                                    <div className="col-6 col-md-3 align-center">
                                        <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                            14+
                                            <br/>
                                            <br/>
                                            <span className="halfem">Ages</span>
                                        </p>
                                    </div>
                                    <div className="col-6 col-md-3 align-center">
                                        <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                            $30
                                            <br/>
                                            <br/>
                                            <span className="halfem">Per Player</span>
                                        </p>
                                    </div>
                                </div>
                        </section>
                        </div>
                    </section>
                </article>

                <LazyLoad height={600} once offset={100}>
                <section id="two" className="wrapper alt style2">

                {
                    /*
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/escaperoomvr.jpg")} alt="" /></div><div className="content">
                           <h2>Next-Generation Escape Rooms</h2>
                           <p></p>
                           <p>The escape rooms at Mirage VR are unlike anything you've ever tried. We put you into environments
                              and puzzles that are impossible in real life, but <span className="mirage-teal"> completely natural in virtual reality. </span>
                              Magical Contraptions and Infinite Labyrinths await you here.
                           </p>
                            <p>
                            <a id="gamepage-book" className="button small special" href="#experiences" alt="">See Escape Rooms</a>
                            </p>
                        </div>
                    </section>
                    */
                }
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/escaperoomvr.jpg")} alt="" /></div><div className="content">
                           <h2>Flagship Experience - <span className="mirage-teal">Decomposed</span></h2>
                           <p></p>
                           <p>The escape rooms at Mirage VR are unlike anything you've ever tried. We put you into environments
                              and puzzles that are impossible in real life, but <span className="mirage-teal"> completely natural in virtual reality. </span>
                           </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/decomposed-seated2.jpg")} alt="" /></div><div className="content">
                            <h2>Seated VR Escape Experience</h2>
                            <p></p>
                            <p>Solve mind-bending puzzles and impossible contraptions all from the comfort of your own seat. You and your friends have been 
                            transformed into a ghost, and the only way to get your real body back is to escape.
                            </p>
                            <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Decomposed</a>

                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/decomposed-elevator.jpg")} alt="" /></div><div className="content">
                            <h2>Journey Through Han's Mansion</h2>
                            <p></p>
                            <p>With every twist and turn, you question what is real and where you truly are. An atmospheric experience only possible in Virtual Reality.
                            </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/decomposed-button.jpg")} alt="" /></div><div className="content">
                            <h2>Mind-Bending puzzles</h2>
                            <p></p>
                            <p>The normal laws of reality don't apply in Han's Mansion. You will find contraptions, puzzles, and labyrinths that defy all human logic, yet
                            make perfect sense. Can you work together to escape the Mansion, or will you remain trapped forever?
                            </p>
                        </div>
                    </section>
                </section>
                </LazyLoad>

                {
                    /*
                    
                                   <LazyLoad height={400} once offset={100}>
                    <section className="wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                        <div className="inner">
                        <header className="major less-heavy-text">
                                <h2>Work together to escape Impossible Worlds, filled with <span className="mirage-teal">Magical Puzzles, Contraptions, and Obstacles.
                                </span>
                                </h2>
                        </header>
                        </div>
                    </section>
                    <section className="wrapper style5">
                        <div className="inner">
                          <section>
                          <div className="row description">
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/butterfly.png")}/>
                              <h3>Breathtaking<br/> Environments</h3>
                              <p>We put you in environments that are more immersive than any escape room you've ever tried.
                                 Your mind and senses completely believe that it's real.</p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/fullbodyvr.png")}/>
                              <h3>Beyond Physical<br/> Limits</h3>
                              <p>Forget about escaping a room... You are trapped in an entire world! Can you escape?</p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/escapeeye.png")}/>
                              <h3>Cutting-edge<br/> VR Technology</h3>
                              <p>Our experiences are enabled by a high-end computers and top tier VR headsets. <a className="hide" href="" style={{borderBottom: '1px solid'}}>Learn More</a></p>
                            </div>
                          </div>
                          </section>
                        </div>
                      </section>
                </LazyLoad>



                <a id="experiences"></a>
                <article className="wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                    <div className="inner">
                        <section>
                        <header className="major less-heavy-text">
                            <h1><span className="mirage-white">Choose Your Adventure</span>
                            </h1>
                        </header>
                        </section>
                    </div>
                </article>
               <LazyLoad height={600} once offset={600}>
                <ExperienceChooser category="EscapeRoom" groupSize="1to40" hideCategory="true"/>
                    <section className="faq-inline wrapper style7 special no-border" style={{display:'none', padding: '2em 2em 1em 2em'}}>
                        <div className="inner">
                            <header className="major less-heavy-text">
                                    <h1 >Have more questions?</h1>
                            </header>
                            <a className="button small" href="/faq">See FAQ</a>
                        </div>
                    </section>
               </LazyLoad>

                    */
                }

                <section className="wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                    <div className="inner">
                    <header className="major less-heavy-text">
                            <h2>Work together to escape Impossible Worlds, filled with <span className="mirage-teal">Magical Puzzles, Contraptions, and Obstacles.
                            </span>
                            </h2>
                            <br/>
                            <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Now!</a>
                    </header>
                    </div>
                </section>

                <LazyLoad height={600} once offset={600}>
                    <EscapeRoomTestimonials />
                </LazyLoad>

                <BookModal 
                    appointmentType="decomposed"
                    isGroupReservation={false}
                    showModal={this.state.showModal}
                    exitModal={this.exitModal}
                />
            </div>
        )
    }
};

// const img = {
//     backgroundImage: 'url("https://res.cloudinary.com/teepublic/image/private/s--E-fxVegu--/t_Preview/b_rgb:ffffff,c_lpad,f_jpg,h_630,q_90,w_1200/v1515227040/production/designs/2258222_3.jpg")', /*Just for clarrification case*/
//     textAlign: 'center !important',
//     margin: '0 auto !important'
// }
// const imgDiv = {
//     backgroundImage: 'url("https://s33.postimg.cc/b1rse32sv/dragonfire.jpg")',
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'auto',
//     display: 'inline !important',
//     //margin: 'auto !important',
//     position: 'fixed !important',
// }